/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.p, null, "Que buena noticia nos da ", React.createElement(_components.a, {
    href: "http://lasindias.com/el-ministerio-de-salud-de-jamaica-inicia-su-migracion-a-software-libre"
  }, "Manuel en el Correo de las Indias"), ", un gran avance para ", React.createElement(_components.a, {
    href: "http://lasindias.com/gnu-health-jamaica-y-la-cruzada-para-liberar-la-salud"
  }, "los sistemas ERM libres"), ". En este blog ya ", React.createElement(_components.a, {
    href: "http://litox.entramado.net/2013/02/09/devolvednos-nuestro-historial-medico/"
  }, "hablamos hace casi un año de la importancia de ser propietario de los datos sobre tu salud"), ", al margen de quien te trate o cree esos datos, tener la soberanía sobre tu salud no es algo difícil y con estos pasos vamos avanzando en ese camino, además de liberar a estados pobres de la tiranía del software propietario.");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
